import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"

import HeaderImage from "../assets/img/new/tlo-serch-01.webp"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Regulations = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("en")
  }, [])
  return (
    <Layout
      seo={{
        title: "Read the terms and conditions of the WeNet SEO audit website",
        description:
          "Check out the current and detailed terms and conditions for the free SEO audit. There you will find all the information about our services.",
        hreflangs: {
          pl: "regulamin/",
          en: "en/regulations/",
          de: "de/satzung/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/en/regulations/",
                name: t("link-text-regulations"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-regulations"),
                text: t("link-text-regulations"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>Regulations of the Website</h1>
          <p style={{ marginBottom: "40px" }}>audytseo.wenet.pl</p>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
      <section className="standard-section regulations-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2>
                Article 1 <br /> Introduction
              </h2>
              <ol>
                <li>
                  <p>
                    These Regulations govern the rules and way of using the Website{" "}
                    <Link to="/">audytseo.wenet.pl</Link>, owned and published by WeNet Group Spółka Akcyjna (WeNet
                    Group S.A.) with its registered office in Warsaw at ul. Postępu 14, entered in the Register of
                    Entrepreneurs kept by the District Court for the Capital City of Warsaw, 13th Commercial Division,
                    KRS (National Court Register number): 0000681163, with a share capital of PLN 4,950,000.00. The
                    rules contained in these Regulations are without prejudice to the rules concerning other services or
                    products available in the offer of WeNet Group.
                  </p>
                </li>
                <li>
                  <p>
                    The website <Link to="/">audytseo.wenet.pl</Link> allows Users to analyse only publicly available
                    source codes of selected websites in order to determine the degree of optimisation of a given
                    website in terms of the effectiveness of its search on the internet.
                  </p>
                </li>
                <li>
                  <p>
                    The legal framework for the processing of data of natural persons using the website (Users) by WeNet
                    Group and WeNet Sp. z o.o. is specified in the following acts:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the
                        protection of natural persons with regard to the processing of personal data and on the free
                        movement of such data and repealing Directive 95/46/EC - hereinafter referred to as the GDPR;
                      </p>
                    </li>
                    <li>
                      <p>
                        The Act of 18 July 2002 on the provision of electronic services - hereinafter referred to as
                        APES.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
              <h2>
                Article 2 <br /> Definitions
              </h2>
              <ol>
                <li>
                  <p>
                    <strong>User </strong>- any natural person who uses the services offered by the Website via the
                    internet.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Defined User </strong>- a User who has sent his/her personal data to WeNet Group via the
                    'Optimise Your Website' Form or the live chat tool.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>WeNet Group/Service Provider </strong>- the company WeNet Group with its registered office
                    in Warsaw, referred to in Article 1.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Analytical Software </strong>- software used by the Website, enabling the User to conduct
                    SEO Audit, belonging to WeNet Group and protected under the Act 'on copyright and related rights' of
                    4 February 1994.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Website</strong> - the website available at the address{" "}
                    <Link to="/">audytseo.wenet.pl</Link> located on the WeNet Group server, containing:
                  </p>
                  <ol>
                    <li>
                      <p>Analytical software;</p>
                    </li>
                    <li>
                      <p>
                        The 'Optimize Your Website' form that enables the User to receive an SEO Audit Report in the
                        form of a PDF file and to establish a commercial contact with WeNet Group;
                      </p>
                    </li>
                    <li>
                      <p>
                        Live chat tool enabling direct written contact with WeNet Group consultants from 8am to 4pm and
                        off-line contact from 4pm to 8am;
                      </p>
                    </li>
                    <li>
                      <p>
                        Live chat tool enabling direct contact with WeNet Group consultants by phone from 8am to 4pm and
                        off-line contact from 4pm to 8am.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Contact button takes the User to the{" "}
                        <a href="http://wenet.pl" target="_blank" rel="nofollow noreferrer">
                          wenet.pl
                        </a>{" "}
                        website and enables contact via the contact forms on the landing page, in accordance with the
                        Regulations of the{" "}
                        <a href="http://wenet.pl" target="_blank" rel="nofollow noreferrer">
                          wenet.pl
                        </a>{" "}
                        website.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>Commercial information </strong>- a message sent by e-mail containing the SEO Audit Report
                    in PDF format and information concerning the implementation of SEO Services with respect to the
                    websites managed by the User for which an SEO Audit was performed. The User agrees to receive
                    Commercial Information by completing the Contact Form.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>SEO Audit/Report - </strong>analysis of the publicly available source code of a single
                    website selected by the User, in order to determine the degree of optimisation of the website in
                    terms of the effectiveness of its search on the internet.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>SEO Audit Report/Report - </strong>the result of the audit presented to the User on the
                    screen of his/her end device or sent to the User at his/her request by e-mail in the form of a PDF
                    file. The SEO Audit Report in the form of a PDF file contains more information than the SEO Audit
                    Report presented on the screen of the end device, including substantive descriptions of each Audit
                    criterion checked.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>End device</strong> - computer, tablet or telephone connected to the internet, used by the
                    User.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>SEO services - </strong>services provided by WeNet Group, consisting in optimisation of
                    websites in terms of increasing the effectiveness of their search on the internet.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies </strong>- text files containing IT data. Cookies are mostly used in case of
                    counters, surveys, online shops, pages which require logging in, advertisements and to monitor the
                    activity of visitors.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Regulations</strong> – these Regulations of the Website available at:{" "}
                    <Link to="/">audytseo.wenet.pl.</Link>
                  </p>
                </li>
              </ol>
              <h2>
                Article 3 <br /> Types and scope of Services provided electronically to Users
              </h2>
              <ol>
                <li>
                  <p>
                    As part of the Website, WeNet Group provides the Users with the following Services free of charge:
                  </p>
                  <ol>
                    <li>
                      <p>Performing an SEO Audit with displaying the Report on the End Device screen;</p>
                    </li>
                    <li>
                      <p>
                        Performing an SEO Audit with sending the Report in PDF format to the User's e-mail address
                        indicated by him/her in the Contact Form along with the initial Commercial Offer for the SEO
                        Service;
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    The website does not sell SEO Services directly. The implementation of SEO Services by WeNet Group
                    takes place on the basis of agreements concluded with customers outside the Website.
                  </p>
                </li>
              </ol>
              <h2>
                Article 4 <br /> Terms of use of the website
              </h2>
              <ol>
                <li>
                  <p>
                    WeNet Group authorises the User to use the Website and perform an SEO Audit only to a limited
                    extent, resulting from the provisions of these Regulations.
                  </p>
                </li>
                <li>
                  <p>The technical conditions for using the Website are:</p>
                  <ol>
                    <li>
                      <p>internet access,</p>
                    </li>
                    <li>
                      <p>
                        a web browser installed on the end device, for example one of the following: Firefox, Chrome,
                        Safari, IE, Opera, updated to the latest version.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>All rights to the Analysis Software are vested in WeNet Group.</p>
                </li>
                <li>
                  <p>
                    By using the Website, the User does not acquire any rights, in particular copyrights or licenses, to
                    the content contained on the Website, which remain the property of WeNet Group and its
                    counterparties. The Analytical Software may not be downloaded electronically by the User or used to
                    submit any commercial offers to third parties.
                  </p>
                </li>
                <li>
                  <p>
                    The User is not entitled to provide third parties with free or paid reports generated by the
                    Analytical Software. The User is also not entitled to generate queries through the software and to
                    perform reverse engineering operations against the mechanism of the Analytical Software to
                    distribute in any form the Reports obtained in this way in part or in whole.
                  </p>
                </li>
                <li>
                  <p>
                    The rights to works, trademarks and Analytical Software made available on the Website are owned by
                    WeNet Group or third parties. If the rights in question are vested in third parties, WeNet Group
                    presents works, trademarks and databases on the Website on the basis of relevant contracts concluded
                    with these persons, in particular license agreements and agreements for the transfer of proprietary
                    copyrights, or on the basis of legal regulations applicable in this regard.
                  </p>
                </li>
                <li>
                  <p>
                    Commercial information sent to the Defined User does not constitute an offer within the meaning of
                    the Civil Code. It constitutes merely an invitation for the User to submit a request for quotation
                    to WeNet Group or authorize WeNet Group to establish commercial contact with the User.
                  </p>
                </li>
                <li>
                  <p>
                    It is forbidden for Users to post illegal content, content contrary to decency and infringing the
                    rights of third parties using the Website's communication tools.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group reserves the right to introduce restrictions on access to the Website without giving any
                    reason, in particular against Users who violate the rules of using the Website.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group reserves the right to temporarily suspend the provision of Services in connection with
                    the modernisation or reconstruction of the Website or maintenance works of the ICT system.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group is not responsible for interruptions in the provision of Services resulting from reasons
                    beyond its control, in particular force majeure.
                  </p>
                </li>
              </ol>
              <h2>
                Article 5 <br /> Conditions for concluding and terminating an agreement with WeNet Group for the
                provision of Services by electronic means
              </h2>
              <p>
                In the case of each Website User, conclusion and termination of the agreement for electronic provision
                of Services shall take place each time the Website content is viewed and Services available to the User
                are used.
              </p>
              <h2>
                Article 6 <br /> Liability
              </h2>
              <ol>
                <li>
                  <p>WeNet Group shall not be liable:</p>
                  <ol>
                    <li>
                      <p>
                        for the Defined User' s provision of data inconsistent with the actual state of affairs,
                        incomplete or outdated;
                      </p>
                    </li>
                    <li>
                      <p>
                        for damage caused by the User providing the information referred to in section 2 in the live
                        chat tools.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Each User is fully responsible for breaking the law or for damage caused by his/her actions on the
                    Website, in particular by providing false data, disclosing a legally protected secret or other
                    confidential information, violating personal rights or intellectual property rights of third
                    parties.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group shall not be responsible, in particular in terms of compensation, towards any User for
                    any losses resulting from incorrectness, outdatedness or quality of data contained in SEO Audit
                    Reports.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group is not responsible for the actions of third parties or any Users who use the SEO Audit
                    Reports in a manner inconsistent with the rules of using the Website.
                  </p>
                </li>
                <li>
                  <p>
                    In no event shall WeNet Group be liable for any damage or viruses that may infect any User's
                    equipment while using the Website, and in particular in connection with downloading any materials,
                    files, text, photos, graphics, audio or video from it.
                  </p>
                </li>
              </ol>
              <h2>
                Article 7 <br /> Statistics
              </h2>
              <p>
                WeNet Group reserves the right to collect and process anonymous statistics on Users in order to learn
                about their preferences and assess compliance with the rules of using the Website and the Regulations.
                The above data does not constitute personal data within the meaning of applicable law and is not subject
                to protection provided for therein.
              </p>
              <h2>
                Article 8 <br /> Provisions regarding cookies
              </h2>
              <p>
                Detailed information on the technologies used by WeNet, including the types and purposes of the use of
                cookies, is available in the <Link to="/en/cookies-policy">Cookies Policy</Link>.
              </p>
              <h2>
                Article 9 <br /> Rules for the processing of personal data
              </h2>
              <ol>
                <li>
                  <p>
                    WeNet Group with its registered office in Warsaw, at ul. Postępu 14, 02-676 Warsaw, entered in the
                    Register of Entrepreneurs kept by the District Court for the Capital City of Warsaw, 13th Economic
                    Department of the National Court Register, KRS (National Court Register number): 0000681163, with a
                    share capital of PLN 4,950,000 and WeNet Sp. z o.o. with its registered office in Warsaw, at ul.
                    Postępu 14, 02-676 Warsaw, entered in the Register of Entrepreneurs kept by the District Court for
                    the Capital City of Warsaw, 13th Economic Department of the National Court Register, KRS (National
                    Court Register number): 0000116894, with a share capital of PLN 12,469,000 are the data controllers
                    within the meaning of the provisions of the GDPR:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Defined Users, in terms of data made available to WeNet Group S.A. and WeNet Sp. z o.o. in the
                        Contact Form or by using live chat tools, i.e. name, surname, name and VAT of the represented
                        company, e-mail address, fixed or mobile telephone number, content of correspondence conducted
                        with WeNet Group S.A. and WeNet Sp. z o.o. by using live chat tools and cookies.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Data of Defined Users is processed:</p>
                  <ol>
                    <li>
                      <p>
                        for the purpose of rendering electronic services by WeNet Group S.A. and WeNet Sp. z o.o.,
                        referred to in these Regulations, within the meaning of Article 6(1)(b) of the GDPR and on the
                        basis of consent granted pursuant to Article 6(1)(a) of the GDPR, which may be withdrawn by the
                        subject at any time, without affecting the legality of processing performed on the basis of
                        consent before its withdrawal.
                      </p>
                    </li>
                    <li>
                      <p>
                        for the purpose of marketing activities of WeNet Group S.A. and WeNet Sp. z o.o. within the
                        framework of legally justified interest of the controller pursuant to Article 6(1)(f) of the
                        GDPR in accordance with the statements of will of the Customer concerning data processing and
                        marketing communication specified in the GDPR and in the Act on the provision of electronic
                        services and the Telecommunications Law. Consent granted to WeNet Group S.A. and WeNet Sp. z
                        o.o. within the scope of the above-mentioned marketing communication may be withdrawn by the
                        Customer at any time, without affecting the legality of processing performed on the basis of
                        consent before its withdrawal.
                      </p>
                    </li>
                    <li>
                      <p>
                        for the purpose of fulfilling the legal obligations of the WeNet Group S.A. and WeNet Sp. z o.o.
                        towards the data subject specified in the GDPR, within the meaning of Article 6(1)(c) of the
                        GDPR referred to in section 6 below.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    The data of Defined Users may be made available at the request of authorised state authorities. In
                    addition, cookies relating to it may be made available to advertisers and business partners
                    cooperating with WeNet Group S.A. and WeNet Sp. z o.o.
                  </p>
                </li>
                <li>
                  <p>
                    Data made available by a Defined User are processed by WeNet Group S.A. and WeNet Sp. z o.o. until
                    the User withdraws consent for marketing communication referred to in section 2(b). In addition, the
                    Data of Defined Users is processed for the period required by WeNet Group S.A. and WeNet Sp. z o.o.
                    to perform and document the legal obligations imposed by the provisions of the GDPR towards Users
                    and Registered Users, referred to in section 6.
                  </p>
                </li>
                <li>
                  <p>
                    Every entity whose data is controlled by WeNet Group S.A. and WeNet Sp. z o.o. shall have the right
                    to request WeNet Group S.A. and WeNet Sp. z o.o. to access, rectify, delete or restrict processing
                    of the data to which it relates and the right to object to processing, as well as the right to data
                    portability.
                  </p>
                </li>
                <li>
                  <p>
                    Contact with the Data Protection Officer of the WeNet Group S.A. and WeNet Sp. z o.o.: Wojciech
                    Zaręba, <a href="mailto:iod@wenet.pl">iod@wenet.pl</a>.
                  </p>
                </li>
                <li>
                  <p>
                    Every User whose data is controlled by WeNet Group S.A. and WeNet Sp. z o.o. has a right to lodge a
                    complaint to the President of the Personal Data Protection Office.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group S.A. and WeNet Sp. z o.o. processes personal data in ICT systems, access to which is
                    strictly limited. WeNet Group S.A. and WeNet Sp. z o.o. uses technical and organisational measures
                    to ensure the security of personal data processed on the Website, including protection against
                    disclosure of data to unauthorised persons. ICT systems and procedures used by WeNet Group S.A. and
                    WeNet Sp. z o.o. are regularly monitored in order to detect possible threats.
                  </p>
                </li>
              </ol>
              <h2>
                Article 10 <br /> Complaint procedure
              </h2>
              <ol>
                <li>
                  <p>
                    Complaints concerning the functioning of the Service and questions on the use of the Service should
                    be sent to the e-mail address: <a href="mailto:dok@wenet.pl">dok@wenet.pl</a>. The complaint should
                    contain the data of the person lodging the complaint (name, surname, address) and the justification
                    for the complaint.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group will consider the complaint within 30 calendar days from the date of its receipt and
                    will immediately inform the User by e-mail about the method of its consideration. In a situation
                    where the information provided in the content of the complaint needs to be supplemented, the time
                    for considering the complaint may be extended.
                  </p>
                </li>
                <li>
                  <p>The User has no right to appeal against the content of the reply to the complaint.</p>
                </li>
              </ol>
              <h2>
                Article 11 <br /> Final Provisions
              </h2>
              <ol>
                <li>
                  <p>By using the Website, the User accepts the content of these Regulations.</p>
                </li>
                <li>
                  <p>
                    WeNet Group reserves the right to unilaterally amend the provisions of these Regulations at any time
                    by publishing the current version on the Website.
                  </p>
                </li>
                <li>
                  <p>
                    The rules of using other websites of the Service Provider are regulated by separate regulations
                    published on these websites.
                  </p>
                </li>
                <li>
                  <p>In matters not regulated herein, the provisions of the Polish law shall be applicable.</p>
                </li>
                <li>
                  <p>
                    For the settlement of disputes arising from the provision of the Services specified in these
                    Regulations, the competent court is the common court of the registered office of the Service
                    Provider.
                  </p>
                </li>
                <li>
                  <p>
                    Users have guaranteed access to these Regulations at any time, via the link on the main page of the
                    Website, in a form that allows it to be downloaded, saved and printed.
                  </p>
                </li>
                <li>
                  <p>These Regulations come into force on the date of publication on the Website.</p>
                </li>
              </ol>
              <p>The regulations are valid from 01.01.2023</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Regulations
