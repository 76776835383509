import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout/Layout"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"

import HeaderImage from "../../assets/img/new/tlo-serch-01.webp"

import "../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const CookiesPolicy = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("en")
  }, [])
  return (
    <Layout
      seo={{
        title: "Find out about the cookie policy on the WeNet SEO audit website",
        description:
          "Read detailed information about cookies on the SEO audit website: what they are, what they are used for and who has access to them.",
        hreflangs: {
          pl: "polityka-cookies/",
          en: "en/cookies-policy/",
          de: "de/cookies-politik/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/en/cookies-policy/",
                name: t("link-text-cookies-policy"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-cookies-policy"),
                text: t("link-text-cookies-policy"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>Cookies policy</h1>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
      <section className="standard-section regulations-section cookies-policy">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <p>
                We use cookies in order to improve efficiency and offer the User more and more perfect functionalities
                of the <Link to="/en">audytseo.wenet.pl</Link> website (hereinafter referred to as the Website) and more
                tailored content to the User. In order to ensure transparency of operations, this Cookie Policy is
                adopted, which sets out detailed rules for the use of cookies by the Website.
              </p>
              <p>
                Data by means of cookies are collected by WeNet Group S.A. Address: 14 Postępu St. 02-676 Warsaw, Tax
                Identification Number: 7010046065, Business Identification Number: 140756502 and WeNet Sp. z o.o.
                Address: 14 Postępu St. 02-676 Warsaw, Tax Identification Number: 5210125377, Business Identification
                Number: 012110943. If it is determined that data contained in cookies are personal data, WeNet Group
                S.A. and WeNet Sp. z o.o. are considered to be the Data Administrators, and the User is entitled to all
                rights contained in the Privacy Policy, which is part of the{" "}
                <Link to="/en/regulations">Regulations</Link>.
              </p>
              <p>
                A User is any data subject who uses our Website and other related websites, communications and services
                (hereinafter referred to as User).
              </p>
              <h2>I. COOKIES</h2>
              <p>
                Cookies ("cookies") are pieces of code that are text files that respond to HTTP requests made to our
                server. These files are saved by each browser from which the User visits our Website. Thanks to the
                information contained in cookies, we know how Users use our Website.
              </p>
              <p>
                Cookies are used to ensure optimal handling of the User's visit to the Website and enable faster and
                easier access to information, as well as to offer the User ever-improved functionality of the Website
                and more customized content.
              </p>
              <p>
                Stored information or access to it does not cause any configuration changes in the User's device and
                software installed on it.
              </p>
              <p>
                The information contained in cookies is considered personal data only in connection with other personal
                data available about the User. We, with the help of cookies, try to collect only anonymous data about
                the User, serving the convenience of using the portal and minimizing the nuisance of advertisements.
              </p>
              <h2>II. TYPES AND PURPOSES OF USING COOKIES</h2>
              <p>
                We use cookies and other similar technologies in order to recognize the User during his/her visits to
                our Website, to remember his/her preferences and to display to him/her appropriate, customized content.
                Cookies make the User's interactions with us faster and more effective. In addition, cookies allow us to
                present tailored content to the User, without surveys.
              </p>
              <p>The Website uses two types of cookies:</p>
              <ul>
                <li>
                  <p>
                    <strong>Session cookies</strong> - these are temporary files that remain on the User's device until
                    the User leaves the Service or shuts down the web browser. In this case, the information stored in
                    session cookies is automatically deleted.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Permanent (permanent) cookies</strong> - these are files that remain on the User's device
                    for the period specified in the parameters of cookies or until they are manually deleted.
                  </p>
                </li>
              </ul>
              <p>
                In the area of the Service, other available technologies are also used, allowing information to be
                stored in the browser in appropriate data stores (Session Storage, Local Storage), as well as placed in
                the fragments of codes of analytical tools provided by other providers, which allow cookies to be stored
                in the domains of these services.
              </p>
              <p>The following describes the different categories of cookies are used on our Website:</p>
              <ul>
                <li>
                  <p>
                    <strong>Technical (essential) cookies</strong> - are necessary for the Website to function properly.
                    They relate to the functionality of our websites and allow us to improve the services we offer
                    through our websites, for example, by allowing information to be transferred between pages of our
                    website to avoid having to re-enter information, or by recognizing your preferences when you return
                    to a page of our Website. We use them to:
                  </p>
                  <ul>
                    <li>
                      <p>ensure security</p>
                    </li>
                    <li>
                      <p>maintain your session when you visit and log into your customer account</p>
                    </li>
                    <li>
                      <p>
                        to ensure that the site is displayed appropriately - depending on what device the User is using,
                      </p>
                    </li>
                    <li>
                      <p>to customize our services according to the User's choices</p>
                    </li>
                    <li>
                      <p>to remember whether the User consents to the display of certain content.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Functional cookies</strong> - remember choices made by the User providing a more tailored
                    service.&nbsp;We use them to:
                  </p>
                  <ul>
                    <li>
                      <p>
                        support the functionality of the websites; without them, the website should work correctly but
                        will not be tailored to the User's preferences
                      </p>
                    </li>
                    <li>
                      <p>
                        to provide a high level of functionality of the websites; without them, the functionality of the
                        website may be reduced, but their absence should not prevent the website from being used at all;
                      </p>
                    </li>
                    <li>
                      <p>
                        to use most of the functionality of the websites; blocking them will result in selected
                        functions not working properly.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Analytics cookies</strong> - allow us to recognize and count the number of Users visiting
                    our Service, see how visitors navigate the Service when they use it, and record which content they
                    view and are interested in. This helps us determine how often pages and ads are visited and to
                    determine the most popular areas of our Service. This helps us improve the services we offer by
                    making our Users find the information they are looking for. They are also necessary to settle
                    accounts with our Trusted Partners, to measure the effectiveness of our marketing efforts without
                    identifying personal information. We use them for:
                  </p>
                  <ul>
                    <li>
                      <p>
                        to study statistics on site traffic and to check the source of traffic (redirection
                        directions),&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>detecting various types of abuse, e.g. artificial internet traffic (bots), ddos attacks</p>
                    </li>
                    <li>
                      <p>limiting unwanted marketing activities</p>
                    </li>
                    <li>
                      <p>
                        measuring the effectiveness of marketing campaigns conducted for the Service, e.g. in Google's
                        advertising network, affiliate programs, external websites,
                      </p>
                    </li>
                    <li>
                      <p>to bill Trusted Partners for advertising services based on user activity such as clicks.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Marketing cookies</strong> - used when the User agrees and only when the functionalities and
                    forms of advertising used by our Service require it. We use them in order to:
                  </p>
                  <ul>
                    <li>
                      <p>
                        display advertisements that are relevant and interesting for a specific user. They relate to
                        profiling of advertisements displayed both on external websites and on our Service's website,
                        according to users' preferences in choosing goods, based on data held by the Service, including
                        users' behavior on the Service.&nbsp;
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                By accessing the Website, the User may not agree to the use of analytical and marketing cookies. In that
                case, we will not be able to keep detailed statistics and adjust our advertisements based on the
                information collected on our Service. However, they will still displayed advertisements of a general
                nature both on the Servsu website and on other websites.
              </p>
              <p>
                We use technologies provided by Google and FB to broadcast ads on the Internet, as well as in terms of
                other tools so please note whether you consent to the use of these tools, including profiling of ads on
                the Internet and FB using technologies used by Google which you can do{" "}
                <a
                  href="https://policies.google.com/privacy?hl=pl#infochoices"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  here
                </a>{" "}
                and by FB which you can do{" "}
                <a href="https://pl-pl.facebook.com/privacy/explanation/" target="_blank" rel="nofollow noreferrer">
                  here
                </a>
                .
              </p>
              <p>
                You can find more detailed information about the cookies we use, how long they are stored and the
                current list of our Trusted Partners whose services we use or whose technologies we place on our Service
                in the table: <Link to="/en/cookies-policy/cookies-table">Cookies table</Link>
              </p>
              <h2>III. DISABLING COOKIES</h2>
              <p>
                Web browsers allow the storage of cookies by default. If you do not wish to receive cookies, you may
                change the settings of your browser on the computer or other device you use to access our Service. The
                User's continued use of our Service without changing the settings means that the User agrees to receive
                technical cookies on our Service, which, as we have indicated, are necessary for us to properly operate
                the Service website.
              </p>
              <p>
                Most web browsers have a function that allows the User to view and delete cookies, including cookies on
                our Service. However, it should be emphasized that the inability of our Website to save or read cookies
                may prevent full and correct use of our Website.
              </p>
              <p>
                If you use different devices to access and browse our Service (e.g., computer, smartphone, tablet), you
                should make sure that each browser on each device is adjusted to cookies preferences.
              </p>
              <p>
                How to disable cookies depends on the browser you use. The following indicates how the User can disable
                cookies in Google Chrome, Firefox, Safari, Opera and Internet Explorer.
              </p>
              <h3>Disabling cookies in Google Chrome browser</h3>
              <ul>
                <li>
                  <p>
                    In the upper right corner by the address bar of the Google Chrome browser, select the three vertical
                    dots
                  </p>
                </li>
                <li>
                  <p>In the Privacy security section, click Content.</p>
                </li>
                <li>
                  <p>In the Cookies and page data section, you can change the following cookie settings:</p>
                  <ul>
                    <li>
                      <p>Block all cookies</p>
                    </li>
                    <li>
                      <p>Block third-party cookies</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                For more information about Google Chrome browser settings:&nbsp;
                <a href="https://support.google.com/chrome/#topic=7438008" target="_blank" rel="nofollow noreferrer">
                  https://support.google.com/chrome/#topic=7438008
                </a>
              </p>
              <h3>Disable cookies in Firefox</h3>
              <ul>
                <li>
                  <p>
                    In the upper right corner of the Firefox browser window, click Open menu (three horizontal dashes)
                    and select Settings.
                  </p>
                </li>
                <li>
                  <p>Then from the Privacy and Security section, select the Cookies and Site Data tab.</p>
                </li>
                <li>
                  <p>
                    Select the appropriate security level or configure your own by choosing which elements and scripts
                    to block - cookies:
                  </p>
                  <ul>
                    <li>
                      <p>All from external sites</p>
                    </li>
                    <li>
                      <p>All</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                More information about Firefox browser settings:&nbsp;{" "}
                <a
                  href="https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek
                </a>
                &nbsp;
              </p>
              <h3>Disable cookies in Safari browser</h3>
              <ul>
                <li>
                  <p>
                    Select Safari menu command &gt; Preferences, click on Privacy, and then do any of the following:
                  </p>
                  <ul>
                    <li>
                      <p>Block all cookies</p>
                    </li>
                    <li>
                      <p>Prevent off-site tracking</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                For more information about Safari browser settings:{" "}
                <a
                  href="https://support.apple.com/kb/PH19214?viewlocale=pl_PL&amp;locale=pl_PL"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  https://support.apple.com/kb/PH19214?viewlocale=pl_PL&amp;locale=pl_PL
                </a>
                &nbsp;
              </p>
              <h3>Disable cookies in the Opera browser</h3>
              <ul>
                <li>
                  <p>In the upper right corner next to the Opera browser address bar, select three horizontal lines</p>
                </li>
                <li>
                  <p>Click go to full browser settings</p>
                </li>
                <li>
                  <p>In the Privacy security section, click Cookies and other site data.</p>
                </li>
                <li>
                  <p>You can change the following cookie settings:</p>
                  <ul>
                    <li>
                      <p>Block all cookies</p>
                    </li>
                    <li>
                      <p>Block third-party cookies</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                For more information about Opera browser settings:{" "}
                <a href="http://help.opera.com/Windows/12.10/pl/cookies.html" target="_blank" rel="nofollow noreferrer">
                  http://help.opera.com/Windows/12.10/pl/cookies.html
                </a>
                &nbsp;
              </p>
              <h3>Disable cookies in the Edge browser</h3>
              <ul>
                <li>
                  <p>In the upper right corner by the Edge browser address bar, select the three horizontal dots</p>
                </li>
                <li>
                  <p>Click Settings</p>
                </li>
                <li>
                  <p>Under Cookies and site permissions, click Manage cookies and site data and delete them</p>
                </li>
                <li>
                  <p>You can change the following cookie settings:</p>
                  <ul>
                    <li>
                      <p>Block</p>
                    </li>
                    <li>
                      <p>Block third-party cookies</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                For more information about Edge browser settings:{" "}
                <a href="https://support.microsoft.com/pl-pl/microsoft-edge" target="_blank" rel="nofollow noreferrer">
                  https://support.microsoft.com/pl-pl/microsoft-edge
                </a>
                &nbsp;
              </p>
              <h2>IV. CONTACT INFORMATION</h2>
              <p>
                If you have any doubts about the issues of this Cookies Policy or to obtain information regarding this
                Cookies Policy, you may contact the Administrator, through the Data Protection Officer (Wojciech Zaręba)
                available by e-mail at <a href="mailto:iod@wenet.pl">iod@wenet.pl</a> or by mail to the Administrator's
                address.
              </p>
              <button
                type="button"
                onClick={() => {
                  const _hsp = (window._hsp = window._hsp || [])
                  _hsp.push(["showBanner"])
                }}
                className="btn btn-primary d-block mx-auto mt-5"
              >
                Cookie settings
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CookiesPolicy
