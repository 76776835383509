import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"

import HeaderImage from "../assets/img/new/tlo-serch-01.webp"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Regulations = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("de")
  }, [])
  return (
    <Layout
      seo={{
        title: "Lesen Sie die Allgemeinen Geschäftsbedingungen der WeNet SEO Audit Website",
        description:
          "Informieren Sie sich über die aktuellen und detaillierten Bedingungen für das kostenlose SEO-Audit. Dort finden Sie alle Informationen über unsere Dienstleistungen.",
        hreflangs: {
          pl: "regulamin/",
          en: "en/regulations/",
          de: "de/satzung/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/de/satzung",
                name: t("link-text-regulations"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-regulations"),
                text: t("link-text-regulations"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>Ordnung der Website</h1>
          <p style={{ marginBottom: "40px" }}>audytseo.wenet.pl</p>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
      <section className="standard-section regulations-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2>
                §1 <br /> Einleitung
              </h2>
              <ol>
                <li>
                  <p>
                    Die vorliegende Ordnung bestimmt die Regeln und die Art und Weise der Inanspruchnahme des
                    Internetservices <Link to="/">audytseo.wenet.pl</Link>, dessen Eigentümer und Herausgeber die
                    Gesellschaft WeNet Group Spółka Akcyjna (WeNet Group S.A.) mit Sitz in Warszawa, ul. Postępu 14,
                    registriert im Unternehmerregister, geführt durch das Amtsgericht für die Hauptstadt Warszawa, XIII.
                    Wirtschaftsabteilung, KRS: 0000681163, mit dem vollständig eingezahlten Grundkapital in Höhe
                    4.950.000,00 Zloty, ist. Die in der vorliegenden Ordnung enthaltenen Regeln kollidieren nicht mit
                    den anderen Ordnungen, die die anderen Dienstleistungen oder Produkte, die sich im Angebot von WeNet
                    Group befinden, betreffen.
                  </p>
                </li>
                <li>
                  <p>
                    Der Service <Link to="/">audytseo.wenet.pl</Link> ermöglicht den Nutzern die Durchführung einer
                    Analyse ausschließlich der öffentlich zur Verfügung gestellten Quellencodes ausgewählter Websites,
                    um den Grad der Optimierung der jeweiligen Seite bezüglich der Effektivität ihrer Auffindung im
                    Internet zu bestimmen.
                  </p>
                </li>
                <li>
                  <p>
                    Rechtlicher Rahmen für die Verarbeitung personenbezogener Daten der natürlichen Personen, die den
                    Service nutzen (Nutzer) durch WeNet Group S.A. und WeNet Sp. z o.o. sind in nachfolgenden Gesetzen
                    geregelt:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz
                        natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und
                        zur Aufhebung der Richtlinie 95/46/EG - nachfolgend DS-GVO genannt;
                      </p>
                    </li>
                    <li>
                      <p>
                        Gesetz vom 18. Juli 2002 über die Erbringung von Dienstleistungen auf elektronischem Weg -
                        nachfolgend GEDeW genannt.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
              <h2>
                §2 <br /> Definitionen
              </h2>
              <ol>
                <li>
                  <p>
                    <strong>Nutzer </strong>- bedeutet jede natürliche Person, die mit Hilfe von Internet die vom
                    Service angebotenen Dienstleistungen in Anspruch nimmt.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Definierter Nutzer </strong>– bedeutet den Nutzer, der an WeNet Group die ihn betreffenden
                    personenbezogenen Daten mit Hilfe des Formulars „Optimieren Sie Ihre WWW-Seite” oder mit Hilfe des
                    Tools Live Chat übermittelt hat.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>WeNet Group/Dienstanbieter </strong>- bedeutet die Gesellschaft WeNet Group S.A. mit Sitz in
                    Warszawa, von der im § 1 die Rede ist.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Analytische Software </strong>– bedeutet die vom Service verwendete Software, die dem Nutzer
                    die Durchführung eines SEO-Audits ermöglicht, die zur WeNet Group gehört und die dem Schutz gemäß
                    des Gesetzes „über das Urheberrecht und verwandte Rechte" vom 04. Februar 1994 unterliegt.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Service </strong>– bedeutet die Internetseite mit der Adresse:{" "}
                    <Link to="/">audytseo.wenet.pl</Link> auf dem Server von WeNet Group, die enthält:
                  </p>
                  <ol>
                    <li>
                      <p>Analytische Software;</p>
                    </li>
                    <li>
                      <p>
                        Formular „Optimiere Ihre WWW-Seite", das die Erhaltung des Berichts über das SEO-Audit in Form
                        einer PDF-Datei und die Anknüpfung durch den Nutzer des Handelskontakts mit WeNet Group mit
                        ermöglicht;
                      </p>
                    </li>
                    <li>
                      <p>
                        Das Tool Live Chat, das einen direkten, schriftlichen Kontakt mit den Beratern von WeNet Group
                        in der Zeit Stunden von 8.00 bis 16.00 Uhr und den Off-Line-Kontakt in den Stunden 16:00 - 08:00
                        Uhr ermöglicht;
                      </p>
                    </li>
                    <li>
                      <p>
                        Das Tool Live Chat, das einen direkten Kontakt mit den Beratern von WeNet Group auf
                        elektronischem Weg in der Zeit von 8.00 bis 16.00 Uhr und den Off-Line-Kontakt in der Zeit von
                        16:00 bis 08:00 Uhr ermöglicht;
                      </p>
                    </li>
                    <li>
                      <p>
                        Taste Kontakt, die den Nutzer auf die Seite{" "}
                        <a href="http://wenet.pl" target="_blank" rel="nofollow noreferrer">
                          wenet.pl
                        </a>{" "}
                        leitet und die Anknüpfung des Kontakts über Kontaktformulare auf der Zielseite ermöglicht, gemäß
                        der Ordnung der Seite{" "}
                        <a href="http://wenet.pl" target="_blank" rel="nofollow noreferrer">
                          wenet.pl
                        </a>
                        .
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>Handelsinformation </strong>– eine Nachricht, die per E-Mail geschickt wird, die den Bericht
                    über das SEO-Audit in Form einer PDF-Datei und Informationen, die die Realisierung der
                    SEO-Dienstleistung in Bezug auf die vom Nutzer verwalteten WWW-Seiten, die mit Hilfe des SEO-Audits
                    durchgecheckt wurden, betreffen, enthält. Der Nutzer erteilt seine Einwilligung, die
                    Handelsinformation zu erhalten, indem er das Kontaktformular ausfüllt.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>SEO-Audit/Bericht - </strong>Analyse des öffentlich zur Verfügung gestellten Quellencodes
                    einer einzelnen, vom Nutzer gewählten Website, um den Grad der Optimierung der Seite bezüglich der
                    Effektivität ihrer Auffindung im Internet zu bestimmen.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Bericht über SEO-Audit/Bericht – </strong>das Ergebnis des durchgeführten Audits, das dem
                    Nutzer auf dem Bildschirm seines Endgeräts präsentiert oder dem Nutzer auf seinen Wunsch per E-Mail
                    in Form einer PDF-Datei übersendet wird. Der Bericht über SEO-Audit in Form einer PDF-Datei enthält
                    mehr Informationen als der Bericht über das SEO-Audit, der auf dem Bildschirm des Endgeräts
                    präsentiert wird, darunter meritorische Beschreibung jedes geprüften Audit-Kriteriums.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Endgerät </strong>– Computer, Tablet, oder Telefon, das eine Verbindung mit dem Internet
                    hat, die vom Nutzer verwendet werden.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>SEO-Dienstleistungen – </strong>Dienstleistungen, die von WeNet Group angeboten werden, die
                    in der Optimierung der WWW-Seiten hinsichtlich der Erhöhung der Effektivität ihrer Auffindung im
                    Internet bestehen.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies </strong>– Textdateien, die informatische Daten enthalten. Die Cookies werden
                    meistens im Fall von Zählern, Befragungen, Online-Shops, Seiten, die das Einloggen erfordern,
                    Werbung und für die Beaufsichtigung der Aktivität der Besucher verwendet.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Ordnung </strong>– die vorliegende Ordnung der Website <Link to="/">audytseo.wenet.pl</Link>
                    .
                  </p>
                </li>
              </ol>
              <h2>
                §3 <br /> Arten und Umfang der den Nutzern auf elektronischem Weg angebotenen Dienstleistungen
              </h2>
              <ol>
                <li>
                  <p>WeNet Group bietet den Nutzern kostenlos im Rahmen des Services folgende Dienstleistungen:</p>
                  <ol>
                    <li>
                      <p>
                        Die Durchführung des SEO-Audits und die Einblendung des Berichts auf dem Bildschirm des
                        Endgeräts:
                      </p>
                    </li>
                    <li>
                      <p>
                        Durchführung des SEO-Audits und die Übersendung des Berichts in Form einer PDF-Datei an die im
                        Kontaktformular angegebene E-Mail-Adresse des Nutzers, samt dem vorläufigen kommerziellen
                        Angebot, das die SEO-Dienstleistung betrifft;
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Der Service bietet keinen direkten Verkauf der SEO-Dienstleistungen an. Die Realisierung der
                    SEO-Dienstleistungen von WeNet Group erfolgt auf der Grundlage der Verträge, die mit den Kunden
                    außerhalb des Services geschlossen werden.
                  </p>
                </li>
              </ol>
              <h2>
                §4 <br /> Nutzungsregeln des Services
              </h2>
              <ol>
                <li>
                  <p>
                    WeNet Group berechtigt den Nutzer zur Nutzung des Services und zur Durchführung des SEO-Audits
                    ausschließlich in einem beschränkten Umfang, der sich aus den Bestimmungen der vorliegenden Ordnung
                    ergibt.
                  </p>
                </li>
                <li>
                  <p>Die technischen Bedingungen für die Inanspruchnahme des Services sind:</p>
                  <ol>
                    <li>
                      <p>Zugang zum Internet;</p>
                    </li>
                    <li>
                      <p>
                        der am Endgerät installierte Browser, zum Beispiel einer der folgenden: Firefox, Chrome, Safari,
                        IE, Opera, aktualisiert zur neueste Version.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Alle Rechte an der Analytischen Software stehen WeNet Group zu.</p>
                </li>
                <li>
                  <p>
                    Der Nutzer erwirbt durch die Inanspruchnahme des Services keine Rechte, insbesondere keine
                    Urheberrechte oder Lizenzen über die im Service enthaltenen Inhalte, die das Eigentum von WeNet
                    Group und ihrer Vertragspartner bleiben. Die Analytische Software kann weder elektronisch vom Nutzer
                    heruntergeladen werden noch zur Abgabe von kommerziellen Angeboten an Dritte verwendet werden.
                  </p>
                </li>
                <li>
                  <p>
                    Der Nutzer ist nicht berechtigt, kostenpflichtig oder kostenfrei die durch die Analytische Software
                    generierten Berichte Dritten zu überlassen. Der Nutzer ist auch nicht berechtigt, mit Hilfe der
                    Software Anfragen zu generieren und Reverse Engineering Operationen gegenüber dem Mechanismus der
                    Analytische Software durchzuführen sowie die auf diese Art und Weise gewonnenen Berichte, ganz oder
                    teilweise, zu verbreiten.
                  </p>
                </li>
                <li>
                  <p>
                    Rechte an Werken, Warenzeichen und an Analytischer Software, die im Service zur Verfügung gestellt
                    werden, stehen WeNet Group oder Dritten zu. Sollten die diesbezüglichen Rechte Dritten zustehen,
                    präsentiert WeNet Group im Service Werke, Warenzeichen und Datenbanken in Anlehnung an die
                    entsprechenden Verträge, die mit diesen Personen geschlossen wurden, insbesondere Lizenzverträge und
                    Verträge über die Übertragung der vermögensrechtlichen Urheberrechte oder in Anlehnung an die
                    diesbezüglich geltenden Rechtsvorschriften.
                  </p>
                </li>
                <li>
                  <p>
                    Die kommerziellen Informationen, die an den Definierten Nutzer übermittelt werden, stellen kein
                    Angebot im Sinne der Vorschriften des Zivilgesetzbuchs dar. Sie stellen ausschließlich die Einladung
                    für den Nutzer, eine Angebotsanfrage an WeNet Group zu stellen oder sie berechtigen WeNet Group,
                    Geschäftskontakte mit dem Nutzer anzuknüpfen.
                  </p>
                </li>
                <li>
                  <p>
                    Es ist den Nutzern untersagt, Inhalte mit rechtswidrigem Charakter, die gegen gute Sitten verstoßen
                    und die die Rechte Dritter verletzen, mit Hilfe der Kommunikationswerkzeuge des Services zu
                    platzieren.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group behält sich das Recht vor, Einschränkungen beim Zugang zum Service ohne Grundangabe
                    einzuführen, insbesondere gegenüber den Nutzern, die die Regeln der Servicenutzung verletzen.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group behält sich das Recht vor, die Erbringung der Dienstleistungen zeitweilig einzustellen -
                    im Zusammenhang mit der Modernisierung oder dem Umbau des Services oder den Wartungsarbeiten.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group ist für die Unterbrechungen der Erbringung der Dienstleistungen, die sich seinem
                    Einfluss entziehen, nicht verantwortlich, insbesondere für den Fall der höheren Gewalt.
                  </p>
                </li>
              </ol>
              <h2>
                §5 <br /> Bedingungen für den Abschluss und die Auflösung des Vertrags mit WeNet Group über die
                Erbringung der Dienstleistungen auf elektronischem Weg.
              </h2>
              <p>
                Im Fall jedes Nutzers des Services erfolgt der Abschluss und die Auflösung des Vertrags über die
                Erbringung der Dienstleistungen auf elektronischem Weg durch die jeweilige Durchsicht der Inhalte des
                Services und durch die Inanspruchnahme der für den Nutzer zugänglichen Dienstleistungen.
              </p>
              <h2>
                §6 <br /> Verantwortung
              </h2>
              <ol>
                <li>
                  <p>WeNet Group haftet nicht:</p>
                  <ol>
                    <li>
                      <p>
                        für die Angabe durch den Definierten Nutzer von Daten, die dem tatsächlichen Zustand nicht
                        entsprechen, von unvollständigen oder nicht aktuellen Daten;
                      </p>
                    </li>
                    <li>
                      <p>
                        für die Schäden, die durch das Zurverfügungstellen durch den Nutzer in den Werkzeugen Live Chat
                        der Informationen, von den im Abs. 2 die Rede ist, entstanden sind.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Jeder Nutzer haftet voll wenn er das Gesetz verstößt oder wenn er einen Schaden durch seine
                    Aktivitäten im Service verursacht, insbesondere wenn er unrichtige Angaben macht, das rechtlich
                    geschützte Geheimnis oder eine andere vertrauliche Information preisgibt, die Persönlichkeitsrechte
                    oder die Rechte des geistigen Eigentums verletzt.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group haftet nicht, insbesondere nicht finanziell, gegenüber irgendeinem Nutzer für
                    irgendwelche Verluste, die sich aus Unstimmigkeit, fehlender Aktualität oder Qualität der Daten, die
                    in den Berichten über das SEO-Audit enthalten sind, ergeben.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group haftet nicht für die Handlungen von Drittpersonen oder irgendwelchen Nutzern, die die
                    Berichte über das SEO-Audit auf eine Art und Weise nutzen, die mit den Nutzungsregeln des Services
                    nicht übereinstimmt.
                  </p>
                </li>
                <li>
                  <p>
                    Keinesfalls haftet WeNet Group für irgendwelche Schäden oder Viren, die die Geräte eines Nutzers bei
                    der Nutzung des Services infizieren können, insbesondere im Zusammenhang mit dem Herunterladen von
                    irgendwelchen Materialien, Dateien, Texten, Bildern, Graphiken, Tonaufnahmen oder Bildaufnahmen.
                  </p>
                </li>
              </ol>
              <h2>
                §7 <br /> Statistiken
              </h2>
              <p>
                WeNet Group behält sich das Recht vor, die anonymen Statistiken, die die Nutzer betreffen, zu sammeln
                und zu verarbeiten, um ihre Präferenzen kennenzulernen und um die Einhaltung der Nutzungsregeln des
                Services und der Ordnung zu bewerten. Die obigen Daten stellen keine personenbezogenen Daten im Sinne
                der entsprechenden Rechtsvorschriften dar und unterliegen dem darin vorgesehenen Schutz nicht.
              </p>
              <h2>
                §8 <br /> Bestimmungen über Cookies
              </h2>
              <p>
                Ausführliche Informationen über die von WeNet verwendeten Technologien, einschließlich der Arten und
                Zwecke der Verwendung von Cookies, finden Sie in der{" "}
                <Link to="/de/cookies-politik">Cookies-Politik.</Link>
              </p>
              <h2>
                §9 <br /> Regeln für die Verarbeitung personenbezogener Daten
              </h2>
              <ol>
                <li>
                  <p>
                    WeNet Group S.A. mit Sitz in Warszawa, Adresse: ul. Postępu 14, 02-676 Warszawa, registriert im
                    Unternehmerregister, geführt durch das Amtsgericht für die Hauptstadt Warszawa, XIII.
                    Wirtschaftsabteilung des Nationalen Gerichtsregisters, KRS: 0000681163, mit dem vollständig
                    eingezahlten Grundkapital in Höhe 4.950.000,00 Zloty und WeNet Sp. z o.o. mit Sitz in Warszawa,
                    Adresse: ul. Postępu 14, 02-676 Warszawa, registriert im Unternehmerregister, geführt durch das
                    Amtsgericht für die Hauptstadt Warszawa, XIII. Wirtschaftsabteilung des Nationalen
                    Gerichtsregisters, KRS: 0000116894, mit dem vollständig eingezahlten Grundkapital in Höhe
                    12.469.000,00 Zloty, sind im Sinne der DS-GVO der Verantwortliche für die Verarbeitung
                    personenbezogener Daten:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Definierter Nutzer, in Bezug auf die Daten, die WeNet Group S.A. und WeNet Sp. z o.o. im
                        Kontaktformular oder mit Hilfe der Werkzeuge Live Chat zur Verfügung gestellt werden, d.h.
                        Vorname, Nachname, Bezeichnung und Steueridentifikationsnummer NIP der vertretender Firma,
                        E-Mail-Adresse, Telefonnummer (stationär oder mobil), Inhalt der mit den Werkzeuge Live Chat mit
                        WeNet Group S.A. und WeNet Sp. z o.o. geführten Korrespondenz und Cookies.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Die Daten der Definierten Nutzer werden verarbeitet:</p>
                  <ol>
                    <li>
                      <p>
                        damit die Dienstleistungen durch WeNet Group S.A. und WeNet Sp. z o.o. auf elektronischem Weg
                        erbracht werden, von den in der vorliegenden Ordnung die Rede ist, im Sinne von Art. 6 Abs. 1
                        lit. b DS-GVO und in Anlehnung an die Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO, die
                        jederzeit widerrufen werden kann, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis
                        zum Widerruf erfolgten Verarbeitung berührt wird.
                      </p>
                    </li>
                    <li>
                      <p>
                        damit die Marketingaktivitäten von WeNet Group S.A. und WeNet Sp. z o.o. im Rahmen des
                        berechtigten Interesses des Verantwortlichen realisiert werden, gemäß Art. 6 Abs. 1 lit. f
                        DS-GVO in Anlehnung an die Willenserklärungen des Kunden über die Datenverarbeitung und die
                        Marketingkommunikation, von den in DS-GVO sowie im Gesetz über die Erbringung der
                        Dienstleistungen auf elektronischem Weg die Rede ist. Die WeNet Group S.A. und WeNet Sp. z o.o.
                        erteilten Einwilligungen über die oben erwähnte Marketingkommunikation können von dem Kunden
                        jederzeit widerrufen werden, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
                        Widerruf erfolgten Verarbeitung berührt wird.
                      </p>
                    </li>
                    <li>
                      <p>
                        damit die rechtlichen in DS-GVO genannten Verpflichtungen von WeNet Group S.A. und WeNet Sp. z
                        o.o. gegenüber dem Betroffenen realisiert werden, im Sinne von Art. 6 Abs. 1 lit. c DS-GVO, von
                        den im Abs. 6 nachfolgend die Rede ist.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Die Daten der Definierten Nutzer können auf Aufforderung der berechtigten staatlichen Behörden zur
                    Verfügung gestellt werden. Darüber hinaus können die sie betreffenden Cookies den Werbeanbietern und
                    Geschäftspartnern, die mit WeNet Group S.A. und WeNet Sp. z o.o. zusammenarbeiten zur Verfügung
                    gestellt werden.
                  </p>
                </li>
                <li>
                  <p>
                    Die durch den Definierten Nutzer zur Verfügung gestellten Daten werden von WeNet Group S.A. und
                    WeNet Sp. z o.o. so lange verarbeitet, bis der Nutzer seine Einwilligung zur Marketingkommunkation,
                    von der im Abs. 2 Pkt. b die Rede ist, widerruft. Darüber hinaus werden die Daten der Definierten
                    Nutzer in dem Zeitraum verarbeitet, der für die Erfüllung und Dokumentation durch WeNet Group S.A.
                    und WeNet Sp. z o.o. der rechtlichen Pflichten, die durch die DS-GVO-Vorschriften gegenüber den
                    Nutzern und Definierten Nutzern, von den im Abs. 6 die Rede ist, auferlegt wurden, notwendig ist.
                  </p>
                </li>
                <li>
                  <p>
                    Jeder Betroffene, dessen Daten durch WeNet Group S.A. und WeNet Sp. z o.o. verarbeitet werden, hat
                    das Recht auf Auskunft über seine Daten, das Recht auf ihre Berichtigung, Löschung oder
                    Einschränkung ihrer Verarbeitung, das Recht auf Widerspruch gegen ihre Verarbeitung sowie das Recht
                    auf Datenübertragbarkeit.
                  </p>
                </li>
                <li>
                  <p>
                    Kontakt zum Datenschutzbeauftragten WeNet Group S.A. und WeNet Sp. z o.o.: Wojciech Zaręba,{" "}
                    <a href="mailto:iod@wenet.pl">iod@wenet.pl</a>.
                  </p>
                </li>
                <li>
                  <p>
                    Jeder Nutzer, dessen Daten durch WeNet Group S.A. und WeNet Sp. z o.o. verarbeitet werden, hat das
                    Recht eine Beschwerde beim Präsidenten des Amtes für den Schutz personenbezogener Daten einzulegen.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group S.A. und WeNet Sp. z o.o. verarbeitet personenbezogene Daten in teleinformatischen
                    Systemen, zu den der Zugang strikt eingeschränkt ist. WeNet Group verwendet technische und
                    organisatorische Maßnahmen, deren Ziel die Sicherheit der personenbezogenen Daten, die im Service
                    verarbeitet werden, ist, darin den Schutz vor Preisgabe an die unberechtigten Personen. Die
                    teleinformatischen Systeme und die Prozeduren, die von WeNet Group verwendet werden, werden
                    regelmäßig beaufsichtigt, um eventuelle Gefahren aufzudecken.
                  </p>
                </li>
              </ol>
              <h2>
                §10 <br /> Reklamationsverfahren
              </h2>
              <ol>
                <li>
                  <p>
                    Reklamationen, die das Funktionieren des Services betreffen und die Fragen über die Inanspruchnahme
                    des Services sollen an folgende Adresse gerichtet werden:
                    <a href="mailto:dok@wenet.pl">dok@wenet.pl</a>. Die Reklamation soll die Angaben über die Person,
                    die die Reklamation vorbringt (Vorname, Nachname, Adresse) und die Begründung der Reklamation
                    enthalten.
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group wird die Reklamation innerhalb von 30 Kalendertagen ab ihrem Erhalt prüfen und
                    informiert den Nutzer unverzüglich per E-Mail über das Ergebnis der Prüfung. Sollte es erforderlich
                    sein, die in Reklamation angegebenen Informationen zu ergänzen, kann die für die Prüfung der
                    Reklamation notwendige Zeit verlängert werden.
                  </p>
                </li>
                <li>
                  <p>
                    Dem Nutzer steht kein Recht zu, gegen den Inhalt der Antwort auf die Reklamation einen Widerspruch
                    einzulegen.
                  </p>
                </li>
              </ol>
              <h2>
                §11 <br /> Schlussbestimmungen
              </h2>
              <ol>
                <li>
                  <p>Durch die Benutzung des Services akzeptiert der Nutzer den Inhalt der vorliegenden Ordnung.</p>
                </li>
                <li>
                  <p>
                    WeNet Group behält sich das Recht vor, die Bestimmungen dieser Ordnung jederzeit einseitig zu
                    ändern, indem die aktuelle Version im Service veröffentlicht wird.
                  </p>
                </li>
                <li>
                  <p>
                    Die Regeln der Inanspruchnahme von anderen Services des Dienstanbieters werden in separaten
                    Ordnungen, die in diesen Services veröffentlicht sind, geregelt.
                  </p>
                </li>
                <li>
                  <p>
                    In nicht durch diese Ordnung geregelten Angelegenheiten finden die Vorschriften des polnischen
                    Zivilgesetzbuches Anwendung.
                  </p>
                </li>
                <li>
                  <p>
                    Für die Entscheidung der Streitigkeiten, die sich aus der Erbringung der im vorliegenden Ordnung
                    genannten Dienstleistungen ergeben, ist das für den Sitz des Dienstanbieters zuständige ordentliche
                    Gericht zuständig.
                  </p>
                </li>
                <li>
                  <p>
                    Die Nutzer haben stets den Zugang zur vorliegenden Ordnung, über den Link auf der Homepage des
                    Services, in Form, die das Herunterladen, Speichern und Ausdrucken des Dokuments erlaubt.
                  </p>
                </li>
                <li>
                  <p>Die vorliegende Ordnung tritt am Tag ihrer Veröffentlichung im Service in Kraft.</p>
                </li>
              </ol>
              <p>Die Ordnung gilt ab 01.01.2023.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Regulations
