import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout/Layout"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"

import HeaderImage from "../../assets/img/new/tlo-serch-01.webp"

import "../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const CookiesPolicy = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Poznaj zasady polityki cookies na stronie WeNet audyt SEO",
        description:
          "Zapoznaj się ze szczegółowymi informacjami dotyczącymi plików cookies na stronie audyt SEO: czym są, do czego służą i kto ma do nich dostęp.",
        hreflangs: {
          pl: "polityka-cookies/",
          en: "en/cookies-policy/",
          de: "de/cookies-politik/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/polityka-cookies/",
                name: t("link-text-cookies-policy"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-cookies-policy"),
                text: t("link-text-cookies-policy"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>Polityka Cookies</h1>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
      <section className="standard-section regulations-section cookies-policy">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <p>
                Korzystamy z&nbsp;plików cookies w&nbsp;celu poprawienia efektywności i&nbsp;zaproponowania
                Użytkownikowi coraz doskonalszych funkcjonalności serwisu internetowego&nbsp;<strong>wenet.pl </strong>
                &nbsp;(dalej jako Serwis) oraz&nbsp;bardziej dopasowanych do&nbsp;Użytkownika treści. W&nbsp;celu
                zapewnienia przejrzystości działań zostaje przyjęta niniejsza Polityka Cookies, która określa
                szczegółowe zasady korzystania przez Serwis internetowy z&nbsp;plików cookies.
              </p>
              <p>
                Dane za pomocą plików cookies gromadzone są przez WeNet Group S.A. adres ul. Postępu 14 02-676 Warszawa,
                numer NIP: 7010046065, numer REGON: 140756502 i WeNet SP. z o.o. adres ul. Postępu 14 02-676 Warszawa,
                numer NIP: 5210125377, numer REGON: 012110943. W przypadku stwierdzenia, że dane zawarte w plikach
                cookies są danymi osobowymi WeNet Group S.A. i WeNet Sp. zo.o. uznawani są za Administratorów danych,
                zaś Użytkownikowi przysługują wszystkie uprawnienia zawarte w Polityce Prywatności, będącej częścią{" "}
                <Link to="/regulamin">Regulaminu.</Link>
                &nbsp;
              </p>
              <p>
                Użytkownikiem jest każda osoba, której dane dotyczą, korzystająca z&nbsp;naszego Serwisu internetowego
                oraz&nbsp;innych powiązanych z&nbsp;nim witryn, komunikacji oraz&nbsp;usług (dalej jako&nbsp;
                <em>Użytkownik</em>).
              </p>
              <h2>I. PLIKI COOKIES</h2>
              <p>
                Pliki cookies („ciasteczka”) to&nbsp;fragmenty kodu, które są plikami tekstowymi odpowiadającymi
                zapytaniom HTTP kierowanym do&nbsp;naszego serwera. Pliki te zapisywane są przez każdą przeglądarkę,
                z&nbsp;której Użytkownik odwiedza nasz&nbsp;Serwis. Dzięki informacjom zawartym w&nbsp;plikach cookies
                wiemy, w&nbsp;jaki sposób Użytkownicy korzystają z&nbsp;naszego Serwisu.
              </p>
              <p>
                Pliki cookies służą nam do&nbsp;zapewnienia optymalnej obsługi wizyty Użytkownika w&nbsp;Serwisie
                oraz&nbsp;umożliwiają szybszy i&nbsp;łatwiejszy dostęp do&nbsp;informacji a&nbsp;także w&nbsp;celu
                zaproponowania Użytkownikowi coraz doskonalszych funkcjonalności Serwisu internetowego
                oraz&nbsp;bardziej dopasowanych do&nbsp;Użytkownika treści.
              </p>
              <p>
                Przechowywana informacja lub&nbsp;uzyskiwanie do&nbsp;niej dostępu nie&nbsp;powoduje zmian
                konfiguracyjnych w&nbsp;urządzeniu Użytkownika i&nbsp;oprogramowaniu na&nbsp;nim zainstalowanym.
              </p>
              <p>
                Informacje zawarte w&nbsp;plikach cookies uznawane są za&nbsp;dane osobowe jedynie w&nbsp;powiązaniu
                z&nbsp;innymi danymi osobowymi dostępnymi na&nbsp;temat danego Użytkownika. My&nbsp;za&nbsp;pomocą
                plików cookies staramy&nbsp;się zbierać o&nbsp;Użytkowniku jedynie anonimowe dane, służące
                do&nbsp;wygody korzystania z&nbsp;portalu oraz&nbsp;minimalizacji uciążliwości reklam.&nbsp;
              </p>
              <h2>II. RODZAJE I&nbsp;CELE KORZYSTANIA Z&nbsp;PLIKÓW COOKIES</h2>
              <p>
                Korzystamy z&nbsp;plików cookies i&nbsp;innych podobnych technologii w&nbsp;celu rozpoznawania
                Użytkownika podczas jego&nbsp;wizyt na&nbsp;naszym Serwisie, zapamiętywania jego&nbsp;preferencji
                oraz&nbsp;wyświetlania mu odpowiednich, dostosowanych do&nbsp;niego treści. Dzięki plikom cookies
                interakcje Użytkownika z&nbsp;nami są szybsze i&nbsp;bardziej skuteczne. Ponadto pliki cookies
                umożliwiają nam prezentowanie Użytkownikowi dopasowanych do&nbsp;niego treści, bez&nbsp;konieczności
                przeprowadzania ankiet.
              </p>
              <p>W Serwisie wykorzystywane są dwa rodzaje plików cookies:</p>
              <ul>
                <li>
                  <p>
                    <strong>Cookies sesyjne</strong> – są to&nbsp;pliki tymczasowe, które pozostają na&nbsp;urządzeniu
                    Użytkownika aż do&nbsp;opuszczenia przez niego Serwisu lub&nbsp;wyłączenia przeglądarki
                    internetowej. W&nbsp;takim przypadku informacje zapisane w&nbsp;plikach cookies sesyjnych są
                    automatycznie usuwane.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies stałe (permanentne)</strong> – są to&nbsp;pliki, które pozostają na&nbsp;urządzeniu
                    Użytkownika przez określony w&nbsp;parametrach plików cookies okres lub&nbsp;do&nbsp;momentu
                    ich&nbsp;ręcznego usunięcia.
                  </p>
                </li>
              </ul>
              <p>
                W obszarze Serwisu wykorzystywane są również inne dostępne technologie, pozwalające na&nbsp;zapisywanie
                informacji w&nbsp;przeglądarce w&nbsp;odpowiednich magazynach danych (Session Storage, Local Storage),
                a&nbsp;także umieszczane są w&nbsp;fragmenty kodów narzędzi analitycznych dostarczanych przez innych
                dostawców, które umożliwiają zapisanie plików cookies w&nbsp;domenach tych usług.
              </p>
              <p>Poniżej opisane zostały poszczególne kategorie cookies wykorzystywane są w&nbsp;naszym Serwisie:</p>
              <ul>
                <li>
                  <p>
                    <strong>Cookies techniczne (niezbędne)</strong>&nbsp;- są niezbędne, aby strona Serwisu mogła
                    działać prawidłowo. Odnoszą&nbsp;się one&nbsp;do&nbsp;funkcjonalności naszych witryn internetowych
                    i&nbsp;pozwalają nam ulepszyć usługi, które oferujemy za&nbsp;pośrednictwem naszych witryn
                    internetowych, na&nbsp;przykład poprzez&nbsp;umożliwienie przenoszenia informacji między stronami
                    naszej witryny internetowej, aby uniknąć konieczności ponownego wprowadzania informacji,
                    lub&nbsp;rozpoznając preferencje Użytkownika po&nbsp;powrocie na&nbsp;stronę naszego Serwisu.
                    Wykorzystujemy je w&nbsp;celu:
                  </p>
                  <ul>
                    <li>
                      <p>zapewnienia bezpieczeństwa</p>
                    </li>
                    <li>
                      <p>
                        utrzymania sesji, kiedy Użytkownik odwiedza i&nbsp;loguje&nbsp;się do&nbsp;swojego konta klienta
                      </p>
                    </li>
                    <li>
                      <p>
                        zapewnienia odpowiedniego wyświetlanie strony – w&nbsp;zależności od&nbsp;tego, z&nbsp;jakiego
                        urządzenia korzysta Użytkownik,
                      </p>
                    </li>
                    <li>
                      <p>dostosowywania naszych usług do&nbsp;wyborów&nbsp;Użytkownika</p>
                    </li>
                    <li>
                      <p>zapamiętania, czy&nbsp;Użytkownik wyraża zgodę na&nbsp;wyświetlanie niektórych treści.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Cookies funkcjonalne&nbsp;- </strong>zapamiętują wybory dokonywane przez Użytkownika
                    zapewniając bardziej dopasowane usługi. Wykorzystujemy je w celu:
                  </p>

                  <ul>
                    <li>
                      <p>
                        wsparcia funkcjonalności stron internetowych; bez nich strona internetowa powinna działać
                        poprawnie, jednak nie będzie dostosowana do preferencji użytkownika
                      </p>
                    </li>
                    <li>
                      <p>
                        zapewnienia wysokiego poziomu funkcjonalności stron internetowych; bez nich może obniżyć się
                        poziom funkcjonalności strony internetowej, ale ich brak nie powinien uniemożliwić zupełnego
                        korzystania z niej;
                      </p>
                    </li>
                    <li>
                      <p>
                        użycia w większości funkcjonalności stron internetowych; ich zablokowanie spowoduje, że wybrane
                        funkcje nie będą działać prawidłowo.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    <strong>Cookies analityczne</strong>&nbsp;- pozwalają nam rozpoznać i&nbsp;policzyć liczbę
                    Użytkowników odwiedzających nasz&nbsp;Serwis, zobaczyć, w&nbsp;jaki sposób odwiedzający
                    poruszają&nbsp;się po&nbsp;Serwisie, gdy z&nbsp;niej korzystają oraz&nbsp;rejestrować, które treści
                    przeglądają i&nbsp;którymi są zainteresowani. Pomaga nam to&nbsp;określić, jak często strony
                    i&nbsp;reklamy są odwiedzane oraz&nbsp;w&nbsp;celu określenia najpopularniejszych obszarów naszego
                    Serwisu. Pomaga nam to&nbsp;ulepszyć usługi, które oferujemy, sprawiając że nasi Użytkownicy
                    znajdują informacje, których szukają. Są one&nbsp;również
                    niezbędne,&nbsp;aby&nbsp;rozliczać&nbsp;się z&nbsp;naszymi Zaufanymi Partnerami, mierzyć efektywność
                    naszych działań marketingowych bez&nbsp;identyfikacji danych osobowych. Wykorzystujemy je
                    w&nbsp;celu:
                  </p>
                  <ul>
                    <li>
                      <p>
                        badania statystyk dotyczących ruchu na&nbsp;stronie oraz&nbsp;sprawdzania źródła ruchu (kierunki
                        przekierowania),&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>wykrywania różnego rodzaju nadużycia np. sztuczny ruch internetowy (boty), ataki ddos</p>
                    </li>
                    <li>
                      <p>ograniczenia niepożądanych działań marketingowych</p>
                    </li>
                    <li>
                      <p>
                        mierzenia &nbsp;skuteczność akcji marketingowych prowadzonych na&nbsp;rzecz Serwisu, np.
                        w&nbsp;sieci reklamowej Google, w&nbsp;programach partnerskich, na&nbsp;zewnętrznych stronach
                        internetowych,
                      </p>
                    </li>
                    <li>
                      <p>
                        rozliczania&nbsp;się z&nbsp;Zaufanymi Partnerami za&nbsp;usługi reklamowe w&nbsp;oparciu
                        o&nbsp;aktywność użytkownika &nbsp;np. kliknięcia.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Cookies marketingowe</strong>&nbsp;- używane, gdy Użytkownik wyrazi na&nbsp;to&nbsp;zgodę
                    i&nbsp;tylko&nbsp;wtedy gdy zastosowane przez nasz&nbsp;Serwis funkcjonalności oraz&nbsp;formy
                    reklamy tego wymagają. Wykorzystujemy je w&nbsp;celu:
                  </p>
                  <ul>
                    <li>
                      <p>
                        wyświetlania reklam, które są istotne i&nbsp;interesujące dla konkretnego użytkownika. Dotyczą
                        profilowania reklam &nbsp;wyświetlanych zarówno na&nbsp;zewnętrznych serwisach internetowych,
                        jak i&nbsp;na&nbsp;stronie naszego Serwisu, stosownie do&nbsp;preferencji użytkowników
                        w&nbsp;zakresie wyboru towarów, na&nbsp;podstawie danych posiadanych przez Serwis, w&nbsp;tym
                        zachowania użytkowników w&nbsp;Serwisie.&nbsp;
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Wchodząc na&nbsp;stronę Serwisu Użytkownik może nie&nbsp;zgodzić&nbsp;się na&nbsp;korzystanie
                z&nbsp;plików cookies analitycznych i&nbsp;marketingowych. Wówczas nie&nbsp;będziemy mogli prowadzić
                szczegółowych statystyk oraz&nbsp;dostosowywać naszych reklam w&nbsp;oparciu o&nbsp;informacje zebrane
                w&nbsp;naszym Serwisie. Nadal jednak będą wyświetlane reklamy o&nbsp;charakterze ogólnym zarówno
                na&nbsp;stronie Serwisu, jak i&nbsp;na&nbsp;innych serwisach internetowych.
              </p>
              <p>
                Do emisji reklam w&nbsp;Internecie, a&nbsp;także w&nbsp;zakresie innych narzędzi wykorzystujemy
                technologie dostarczane przez Google i&nbsp;FB dlatego zwróć uwagę czy&nbsp;wyrażasz zgodę
                na&nbsp;stosowanie tych narzędzi, w&nbsp;tym profilowanie reklam w&nbsp;Internecie i&nbsp;FB
                za&nbsp;pomocą technologii stosowanych przez Google co możesz zrobić&nbsp;
                <a
                  href="https://policies.google.com/privacy?hl=pl#infochoices"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  tu
                </a>
                &nbsp;i&nbsp;przez FB co możesz zrobić&nbsp;
                <a href="https://pl-pl.facebook.com/privacy/explanation/" target="_blank" rel="nofollow noreferrer">
                  tu
                </a>
                .
              </p>
              <p>
                Bardziej szczegółowe informacje o&nbsp;używanych przez nas plikach cookie, czasie przechowywania
                oraz&nbsp;aktualnej liście naszych Zaufanych Partnerów, z&nbsp;których usług korzystamy lub&nbsp;których
                technologie umieszczamy w&nbsp;naszym Serwisie znajdziesz tabeli:&nbsp;
                <Link to="/polityka-cookies/tabela-cookies">Tabela Cookies</Link>
              </p>
              <h2>III. WYŁĄCZENIE OBSŁUGI PLIKÓW COOKIES</h2>
              <p>
                Przeglądarki internetowe domyślnie dopuszczają przechowywanie plików cookies. Jeśli Użytkownik
                nie&nbsp;chce otrzymywać plików cookies, może zmienić ustawienia swojej przeglądarki na&nbsp;komputerze
                lub&nbsp;innym urządzeniu, z&nbsp;którego korzysta w&nbsp;celu dostępu do&nbsp;naszego Serwisu. Dalsze
                korzystanie przez Użytkownika z&nbsp;naszego Serwisu bez&nbsp;zmiany ustawień oznacza, że Użytkownik
                zgadza&nbsp;się na&nbsp;otrzymywanie plików cookies technicznych w&nbsp;naszym Serwisie, które jak
                wskazaliśmy są dla nas niezbędne do&nbsp;prawidłowego funkcjonowania strony Serwisu.
              </p>
              <p>
                Większość przeglądarek internetowych posiada funkcję, która pozwala Użytkownikowi przeglądać
                i&nbsp;usuwać pliki cookie, w&nbsp;tym pliki cookie w&nbsp;naszym serwisie. Jednakże podkreślić należy,
                że brak możliwości zapisywania lub&nbsp;odczytywania plików cookies przez stronę internetową naszego
                Serwisu może uniemożliwić pełne i&nbsp;poprawne korzystanie z&nbsp;naszego Serwisu.
              </p>
              <p>
                Jeżeli Użytkownik używa różnych urządzeń do&nbsp;uzyskania dostępu do&nbsp;naszego Serwisu
                oraz&nbsp;jego&nbsp;przeglądania (np. komputera, smartfona, tabletu) powinien upewnić się, że każda
                przeglądarka na&nbsp;każdym urządzeniu dostosowana jest do&nbsp;preferencji związanych z&nbsp;plikami
                cookies.
              </p>
              <p>
                Sposób wyłączenia obsługi plików cookies zależy od&nbsp;przeglądarki, z&nbsp;której Użytkownik korzysta.
                Poniżej wskazany został sposób, w&nbsp;jaki Użytkownik może wyłączyć obsługę plików cookies
                w&nbsp;przeglądarce Google Chrome, Firefox, Safari, Opera oraz&nbsp;Internet Explorer.
              </p>
              <h3>Wyłączenie obsługi plików cookies w&nbsp;przeglądarce Google Chrome</h3>
              <ul>
                <li>
                  <p>W prawy górnym rogu przy pasku adresu przeglądarki Google Chrome wybierz trzy kropki pionowe</p>
                </li>
                <li>
                  <p>W sekcji Prywatność bezpieczeństwo kliknij przycisk Treść.</p>
                </li>
                <li>
                  <p>W sekcji Pliki cookies i&nbsp;dane stron możesz zmienić następujące ustawienia plików cookies:</p>
                  <ul>
                    <li>
                      <p>Blokuj wszystkie pliki cookies</p>
                    </li>
                    <li>
                      <p>Blokuj pliki cookies innych firm</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Więcej informacji o&nbsp;ustawieniach przeglądarki Google Chrome:&nbsp;{" "}
                <a href="https://support.google.com/chrome/#topic=7438008" target="_blank" rel="nofollow noreferrer">
                  https://support.google.com/chrome/#topic=7438008
                </a>
              </p>
              <h3>Wyłączenie obsługi plików cookies w&nbsp;przeglądarce Firefox</h3>
              <ul>
                <li>
                  <p>
                    W prawym górnym rogu okna przeglądarki Firefox kliknij Otwórz menu (trzy poziome kreski)
                    i&nbsp;wybierz Ustawienia.
                  </p>
                </li>
                <li>
                  <p>
                    Następnie z&nbsp;sekcji Prywatność i&nbsp;bezpieczeństwo wybierz zakładkę ciasteczka i&nbsp;dane
                    witryn.
                  </p>
                </li>
                <li>
                  <p>
                    Wybierz odpowiedni poziom bezpieczeństwa lub&nbsp;skonfiguruj własny wybierając które elementy
                    i&nbsp;skrypty blokować - ciasteczka:
                  </p>
                  <ul>
                    <li>
                      <p>Wszystkie z&nbsp;zewnętrznych witryn</p>
                    </li>
                    <li>
                      <p>wszystkie</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Więcej informacji o&nbsp;ustawieniach przeglądarki Firefox:&nbsp;{" "}
                <a
                  href="https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek
                </a>
              </p>
              <h3>Wyłączenie obsługi plików cookies w&nbsp;przeglądarce Safari</h3>
              <ul>
                <li>
                  <p>
                    Wybierz polecenie menu Safari &gt; Preferencje, kliknij w&nbsp;Prywatność, a&nbsp;następnie wykonaj
                    dowolną z&nbsp;poniższych czynności:
                  </p>
                  <ul>
                    <li>
                      <p>Blokuj wszystkie cookie</p>
                    </li>
                    <li>
                      <p>Zapobiegaj śledzeniu poza witryną</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Więcej informacji o&nbsp;ustawieniach przeglądarki Safari:{" "}
                <a
                  href="https://support.apple.com/kb/PH19214?viewlocale=pl_PL&amp;locale=pl_PL"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  https://support.apple.com/kb/PH19214?viewlocale=pl_PL&amp;locale=pl_PL
                </a>
              </p>
              <h3>Wyłączenie obsługi plików cookies w&nbsp;przeglądarce Opera</h3>
              <ul>
                <li>
                  <p>W prawy górnym rogu przy pasku adresu przeglądarki Opera wybierz trzy linie poziome</p>
                </li>
                <li>
                  <p>Kliknij przejdź do&nbsp;pełnych ustawień przeglądarki</p>
                </li>
                <li>
                  <p>W sekcji Prywatność bezpieczeństwo kliknij przycisk Ciasteczka i&nbsp;inne dane witryn.</p>
                </li>
                <li>
                  <p>Możesz zmienić następujące ustawienia plików cookies:</p>
                  <ul>
                    <li>
                      <p>Blokuj wszystkie pliki cookies</p>
                    </li>
                    <li>
                      <p>Blokuj pliki cookies innych firm</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Więcej informacji o&nbsp;ustawieniach przeglądarki Opera:{" "}
                <a href="http://help.opera.com/Windows/12.10/pl/cookies.html" target="_blank" rel="nofollow noreferrer">
                  http://help.opera.com/Windows/12.10/pl/cookies.html
                </a>
              </p>
              <h3>Wyłączenie obsługi plików cookies w&nbsp;przeglądarce Edge</h3>
              <ul>
                <li>
                  <p>W prawy górnym rogu przy pasku adresu przeglądarki Edge wybierz trzy kropki poziome</p>
                </li>
                <li>
                  <p>Kliknij Ustawienia</p>
                </li>
                <li>
                  <p>
                    W sekcji Pliki cookie i&nbsp;uprawnienia witryny kliknij Zarządzaj plikami cookie i&nbsp;danymi
                    witryn oraz&nbsp;usuwaj je
                  </p>
                </li>
                <li>
                  <p>Możesz zmienić następujące ustawienia plików cookies:</p>
                  <ul>
                    <li>
                      <p>Blokuj</p>
                    </li>
                    <li>
                      <p>Blokuj pliki cookies innych firm</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Więcej informacji o&nbsp;ustawieniach przeglądarki Edge:{" "}
                <a href="https://support.microsoft.com/pl-pl/microsoft-edge" target="_blank" rel="nofollow noreferrer">
                  https://support.microsoft.com/pl-pl/microsoft-edge
                </a>
              </p>
              <h2>IV. INFORMACJE KONTAKTOWE</h2>
              <p>
                W razie jakichkolwiek wątpliwości związanych z kwestiami niniejszej Polityki Cookies lub w celu
                uzyskania informacji dotyczących niniejszej Polityki Cookies Użytkownik może skontaktować się z
                Administratorami, za pośrednictwem Inspektora Ochrony Danych, w osobie Wojciecha Zaręby, dostępnym pod
                adresem e-mail <a href="mailto:iod@wenet.pl">iod@wenet.pl</a> lub drogą pocztową na adres
                Administratorów.
              </p>
              <button
                type="button"
                onClick={() => {
                  const _hsp = (window._hsp = window._hsp || [])
                  _hsp.push(["showBanner"])
                }}
                className="btn btn-primary d-block mx-auto mt-5"
              >
                Ustawienia cookies
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CookiesPolicy
